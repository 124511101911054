import * as React from 'react';
import useScrollPosition from '@react-hook/window-scroll';

import {
  Container,
  Logo,
  MenuButton,
  MembersContainer,
  Members,
  MembersNumber,
  MoreLoadRow,
  Plus,
  MoreLoadText,
} from './styles';

import Member from '../Member';

const SLICE_STEP = 6;

const MembersFiltered = ({ menuIsShown, members, user, onMemberClick }) => {
  const [loadMoreSlice, setLoadMoreSlice] = React.useState(SLICE_STEP);

  const scrollY = useScrollPosition(60);
  const hide = scrollY < 120;

  const handleLoadMore = () => setLoadMoreSlice((prevSlice) => prevSlice + SLICE_STEP);

  const slicedMembers = members.slice(0, loadMoreSlice);

  const hasMoreMembers = members.length > loadMoreSlice;

  return (
    <Container>
      <Logo visible={!hide} />
      <MenuButton visible={!hide} menuIsShown={menuIsShown} />
      <MembersContainer>
        <MembersNumber>{`${members.length} members match your search`}</MembersNumber>
        <Members>
          {slicedMembers.map((member, index) => (
            <Member
              big
              hasMarginRight={index === 1}
              member={member}
              marginR={index % 2 === 0}
              marginL={index % 2 !== 0}
              marginB={index === 0 || index === 4}
              marginRBig={(index + 1) % 7 === 0}
              summary
              onMemberClick={onMemberClick}
              user={user}
            />
          ))}
        </Members>
        {hasMoreMembers && (
          <MoreLoadRow onClick={handleLoadMore}>
            <Plus></Plus>
            <MoreLoadText>Load more members</MoreLoadText>
          </MoreLoadRow>
        )}
      </MembersContainer>
    </Container>
  );
};

export default MembersFiltered;
