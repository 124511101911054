import * as React from 'react';
import loadable from '@loadable/component';
import { useInterpret } from '@xstate/react';

import { COLORS } from '../utils/theme';
import SEO from '../components/SEO';
import Background from '../components/Background';
import Header from '../components/Header';
import MembersFiltered from '../components/MembersFiltered';
import Library from '../components/Tools/Library';
import { SELECTION_MODE } from '../components/Filters/constants';

import useScrollPosition from '@react-hook/window-scroll';

import {
  Logo,
  MenuButton,
  SectionContainer,
  UserHasToBeLoggedIn,
} from '../components/HomeHeader/styles';

import { contentFetch } from '../lib/content-fetcher';
import NewMembers from '../components/NewMembers';
import InviteColleagues from '../components/InviteColleagues';
import NetworkProfile from '../components/Network/NetworkProfile';

import { profileMachine } from '../components/Network/NetworkProfile/profileMachine';
import NetworkDisclaimer from '../components/NetworkDisclaimer';

const Footer = loadable(() => import('../components/Footer'));

const normalizeString = (str) => {
  return str.trim().replace(/\s/g, '').toLowerCase();
};

const isAnyTrue = (arr) => {
  return arr.some((element) => element === true);
};
const areAllTrue = (arr) => {
  return arr.every((element) => element === true);
};

const NetworkMatchmakerPage = () => {
  const scrollY = useScrollPosition(60);
  const hide = scrollY < 120;

  const [menuIsShown, showMenu] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);
  const [showRegister, setShowRegister] = React.useState(false);
  const [members, setMembers] = React.useState([]);
  const [nameFilter, setNameFilter] = React.useState('');
  const [newMembers, setNewMembers] = React.useState([]);
  const [userSurveyGiveToCans, setUserSurveyGiveToCans] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [countriesRegionsRelations, setCountriesRegionsRelations] = React.useState([]);
  const [interestedIn, setInterestedIn] = React.useState([]);
  const [user, setUser] = React.useState(null); // eslint-disable-line

  const [selectedFilterOptions, setSelectedFilterOptions] = React.useState();

  const networkProfileService = useInterpret(profileMachine);

  const goToLogin = () => {
    setShowLogin(true);
    setTimeout(() => setShowLogin(false), 500);
  };

  const goToRegister = () => {
    setShowRegister(true);
    setTimeout(() => setShowRegister(false), 500);
  };

  const getMembers = (query = '') => {
    contentFetch({
      pathname: `/users?member=true&_sort=createdAt:DESC&_limit=-1`,
      setState: setMembers,
      query: query,
    });
  };

  const onApplyFilters = (_query, optionsSelected) => {
    setSelectedFilterOptions(optionsSelected);
  };

  React.useEffect(() => {
    contentFetch({
      pathname: '/users/me',
      setState: setUser,
    });
    contentFetch({
      pathname: '/user-survey-give-to-cans',
      setState: setUserSurveyGiveToCans,
    });
    contentFetch({
      pathname: '/countries?_sort=name:asc&_limit=-1',
      setState: setCountries,
    });
    contentFetch({
      pathname: '/countries-regions-relations?_limit=-1',
      setState: setCountriesRegionsRelations,
    });
    contentFetch({
      pathname: '/User-Survey-Interested-Ins',
      setState: setInterestedIn,
    });
    contentFetch({
      pathname: '/users?member=true&_sort=createdAt:DESC&_limit=5',
      setState: setNewMembers,
    });
    getMembers();
  }, []);

  const getRelatedRegions = (countries) => {
    return countries.map((country) => {
      return countriesRegionsRelations
        .filter((relation) => relation.country_code === country.isoCode)
        .map((region) => region.region_code);
    });
  };

  const getRelatedCountries = (countries) => {
    return countries.map((country) => {
      return countriesRegionsRelations
        .filter((relation) => relation.region_code === country.isoCode)
        .map((country) => country.country_code);
    });
  };

  const selectedCountries = selectedFilterOptions?.tags.countries;

  const relatedRegions = selectedCountries && getRelatedRegions(selectedCountries);
  const relatedCountries = selectedCountries && getRelatedCountries(selectedCountries);

  const hasMemberCountries = (member, countries, operationType) => {
    const matchesCode = countries.map((matchCountry, idx) => {
      const hasCode = member.countries?.some((country) => {
        return (
          country.isoCode === matchCountry.isoCode ||
          relatedRegions[idx].includes(country.isoCode) ||
          relatedCountries[idx].includes(country.isoCode)
        );
      });

      return hasCode;
    });

    return operationType === 'or' ? isAnyTrue(matchesCode) : areAllTrue(matchesCode);
  };

  const hasItemIds = (member, key, items, operationType) => {
    const matchingItems = items.map((item) => {
      return member[key]?.some((member) => member.id === item.id);
    });

    return operationType === 'or' ? isAnyTrue(matchingItems) : areAllTrue(matchingItems);
  };

  const filteredMembers = members.length
    ? members.filter((member) => {
        const hasFullName = normalizeString(member?.firstName + member?.lastName).includes(
          normalizeString(nameFilter),
        );
        if (!hasFullName) return false;

        const hasGiveToCAN = selectedFilterOptions?.tags.giveToCAN.length
          ? hasItemIds(
              member,
              'giveToCAN',
              selectedFilterOptions.tags.giveToCAN,
              selectedFilterOptions.filterTypes.giveToCAN,
            )
          : true;
        if (!hasGiveToCAN) return false;

        const hasInterestedIn = selectedFilterOptions?.tags.interestedIn.length
          ? hasItemIds(
              member,
              'interestedIn',
              selectedFilterOptions.tags.interestedIn,
              selectedFilterOptions.filterTypes.interestedIn,
            )
          : true;
        if (!hasInterestedIn) return false;

        return selectedFilterOptions?.tags.countries.length
          ? hasMemberCountries(
              member,
              selectedFilterOptions.tags.countries,
              selectedFilterOptions.filterTypes.countries,
            )
          : true;
      })
    : [];

  const renderLibrary = () => {
    if (user === null) return null;
    if (!user) {
      return (
        <div>
          <UserHasToBeLoggedIn>You have to be logged in to use the Matchmaker.</UserHasToBeLoggedIn>
          <NewMembers
            members={newMembers}
            joinUs
            goToLogin={goToLogin}
            goToRegister={goToRegister}
          />
        </div>
      );
    }
    return (
      <div>
        <NetworkProfile service={networkProfileService}></NetworkProfile>
        <Library
          notShowMenu
          hasPaddingTop
          menuIsShown={menuIsShown}
          type="MATCHMAKER"
          filters={[
            {
              name: `In search of (allies)`,
              id: 'giveToCAN',
              values: userSurveyGiveToCans,
              selection: SELECTION_MODE.MULTIPLE_CHOICE,
              showAndOrFilterTypes: true,
            },
            {
              name: `In`,
              id: 'countries',
              values: countries,
              selection: SELECTION_MODE.MULTIPLE_CHOICE,
              showAndOrFilterTypes: true,
            },
            {
              name: `around`,
              id: 'interestedIn',
              values: interestedIn,
              selection: SELECTION_MODE.MULTIPLE_CHOICE,
              showAndOrFilterTypes: true,
            },
          ]}
          applyFilters={onApplyFilters}
          onNameChange={(e) => setNameFilter(e)}
          showNameSearch={true}
        />
        <MembersFiltered
          members={filteredMembers}
          user={user}
          onMemberClick={(userId) => {
            networkProfileService.send('SELECT_PROFILE', { userId });
          }}
        />
        <NetworkDisclaimer></NetworkDisclaimer>
        <InviteColleagues
          members={newMembers}
          joinUs
          goToLogin={goToLogin}
          goToRegister={goToRegister}
        />
      </div>
    );
  };

  return (
    <Background color={COLORS.BACKGROUND_LIGHT}>
      <SEO title="Matchmaker"></SEO>
      <Header
        menuIsShown={menuIsShown}
        showMenu={showMenu}
        showLogin={showLogin}
        showRegister={showRegister}
      />
      <SectionContainer>
        <Logo visible={!hide} />
        <MenuButton visible={!hide} menuIsShown={menuIsShown} />
        {renderLibrary()}
      </SectionContainer>
      <Footer />
    </Background>
  );
};

export default NetworkMatchmakerPage;
