import * as React from 'react';
import useScrollPosition from '@react-hook/window-scroll';

import {
  Container,
  Logo,
  MenuButton,
  Title,
  MembersContainer,
  Members,
  InviteText,
  InviteButton,
} from './styles';

import Member from '../Member';

import useMouse from '@react-hook/mouse-position';
import { isSSR } from '../../utils/constants';

const InviteColleagues = ({ members, menuIsShown, joinUs, goToLogin, goToRegister, color }) => {
  const scrollY = useScrollPosition(60);
  const hide = scrollY < 120;

  const membersToShow = members.length > 5 ? members.slice(0, 5) : members;

  const ref = React.useRef(null);

  /* eslint-disable react-hooks/rules-of-hooks */
  let mouse;
  if (!isSSR) {
    mouse = useMouse(ref, {
      enterDelay: 100,
      leaveDelay: 100,
    });
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  return (
    <Container>
      <Logo visible={!hide} />
      <MenuButton visible={!hide} menuIsShown={menuIsShown} />
      <Title>NEW MEMBERS</Title>
      <MembersContainer color={color}>
        <Members>
          {membersToShow.map((member, index) => (
            <Member
              small
              hasMarginRight={index === 1}
              member={member}
              marginR={index % 2 === 0}
              marginL={index % 2 !== 0}
              marginB={index === 0 || index === 4}
            />
          ))}
        </Members>

        <a
          ref={ref}
          href="https://domesticstreamers.typeform.com/to/tRmWt8Yn"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <InviteText>
            Invite your <br />
            colleagues
          </InviteText>
          {!mouse?.isTouch && mouse?.isOver ? (
            <InviteButton x={mouse?.x} y={mouse?.y}>
              Click to invite
            </InviteButton>
          ) : null}
        </a>
      </MembersContainer>
    </Container>
  );
};

export default InviteColleagues;
