import styled from 'styled-components';
import {
  COLORS,
  BREAKPOINTS,
  Logo as LogoBackground,
  MenuButton as MenuButtonBackground,
} from '../../utils/theme';
import PinkTexture1 from '../ArtQualities/WhyArtMatter/pink-textures-1.png';
import PinkTexture2 from '../ArtQualities/WhyArtMatter/pink-textures-2.png';

export const Container = styled.section`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Logo = styled(LogoBackground)`
  background-position: 60px -100%;
  transition: background-position 0.4s;
  ${(props) =>
    props.visible &&
    `
      background-position: 60px 45px;
    `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    background-position: 20px -100%;

    ${(props) =>
      props.visible &&
      `
        background-position: 20px 20px;
      `}
  }
`;

export const MenuButton = styled(MenuButtonBackground)`
  background-position: calc(100% - 40px) -100%;
  transition: background-position 0.4s;
  ${(props) =>
    props.visible &&
    `
      background-position: calc(100% - 40px) 45px;
    `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    background-position: calc(100% - 20px) -100%;

    ${(props) =>
      props.visible &&
      `
          background-position: calc(100% - 20px) 20px;
        `}
  }
`;

export const Title = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 30px 0;
  width: 100%;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.16px;
    box-shadow: none;
    background-color: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    padding: 60px 0 30px;
  }
`;

export const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 64px 80px;
  background-color: ${(props) => props.color || COLORS.BLUE};
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    background-image: url(${PinkTexture2});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    width: 640px;
    height: 500px;
    right: -150px;
    top: 630px;
    z-index: 0;
    transform: scale(0.6);

    @media (max-width: ${BREAKPOINTS.MOBILE}px) {
      right: -250px;
      top: 450px;
      transform: scale(0.2);
    }
  }
  &:after {
    transition: all 0.5s;
    content: '';
    background-image: url(${PinkTexture1});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    width: 640px;
    height: 450px;
    top: -80px;
    left: -200px;
  }
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 0 16px 80px;
  }
`;

export const Members = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-direction: column;
  }
`;

export const InviteText = styled.h1`
  margin: 4rem auto 3.5rem;
  color: ${COLORS.WHITE};
  font-family: Hellix;
  font-size: 148px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.97;
  letter-spacing: -1.64px;
  text-align: center;
`;

export const InviteButton = styled.div`
  textalign: center;
  display: inline-block;
  position: absolute;
  top: 41%;
  left: -1%;
  background: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  padding: 0.8rem 1.5rem;
  border-radius: 1.5rem;
  transform: ${(props) => `translateX(${props.x || 0}px) translateY(${props.y || 0}px);`};
`;
